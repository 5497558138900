.Calculator{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin-top: 20px;
}

.calc-wrapper {
    width:400px;
    height: 600px;
}

.row{
    display:flex;
    width: 100%;
}

.operator {
    background-color: coral;
    color: white;
}