.clear-btn{
    display:flex;
    flex: 1;
    height: 4em;
    align-items: center;
    justify-content: center;
    font-weight: lighter;
    font-size: 1.rem;
    outline: 1px solid #888;
    background-color: #c5c3cd;

}