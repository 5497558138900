.button{
    display:flex;
    height: 4em;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-weight: lighter;
    font-size: 1.4em;
    background-color: #e0e1e6;
    color: #888;
    outline: 1px solid #888;
}