.whole-menu-wrapper{
    top:0;
    position:fixed;
    height: 100%;
    margin-top: 2em;
    margin-left: 2em;
    width: 300px;
    perspective: 50px;
    perspective-origin: left; 
  }
  .menu-button-wrapper {
    position: relative;
    
    
    width: 100%;
    margin-top: 2em;
    transform-style: preserve-3d;
    transform: rotateY(1deg);
  
    padding-bottom: 0.5em;
    height: 70px;
    
    
    /* transform: rotateX(5deg); */
    
  }
  
  .menu-button{
      display: flex;
      background: rgba(0, 0, 0, 0.5);
      color: #f1f1f1;
      padding:0.5em;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    
  }
  .menu-button:hover{
    top:50%;
    height:110%;
    width:110%;
    background:rgba(8, 97, 5, 0.678);
    cursor: url(./custom-cursor.png), auto;
  }